import styled from "styled-components";
import { StyledTooltip } from "components/core/Tooltip/index.style";

const StyledSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Label = styled.label`
  display: flex;
  font-size: 12px;
  align-items: center;
  gap: 4px;
  font-weight: 400;

  > svg {
    display: flex;
  }

  ${StyledTooltip} {
    width: auto !important;
    font-weight: normal;
  }
`;

const SelectDiv = styled.div`
  background: #fff;
  border: 1px solid ${(props) => props.theme.borderColor};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 8px 16px;
  font-size: 16px;
  height: 48px;
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  width: 100%;

  &::after {
    content: "";
    width: 0.8em;
    height: 0.5em;
    background-color: ${(props) => props.theme.baseColor};
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    grid-area: select;
    justify-self: end;
  }

  > select {
    appearance: none;
    background-color: transparent;
    border: none;
    margin: 0;
    width: 100%;
    padding: 0;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;
    grid-area: select;

    &::-ms-expand {
      display: none;
    }
  }
`;

export { StyledSelect, SelectDiv, Label };
