import React from "react";
import { Label, SelectDiv, StyledSelect } from "./index.style";
import Tooltip from "components/core/Tooltip";
import Icon from "components/core/Icon";

interface SelectProps {
  infoTooltip?: string;
  label?: string;
  options?: {
    name: string;
    displayName: string;
  }[];
}

type Props = SelectProps &
  React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >;

const Select = ({
  className,
  infoTooltip,
  label,
  options,
  placeholder,
  ...rest
}: Props) => {
  return (
    <StyledSelect className={className}>
      <Label>
        {label}
        {infoTooltip && (
          <Tooltip
            type={"hover"}
            error={false}
            content={infoTooltip}
            width={200}
          >
            <Icon size={14} type={"info-circle"} />
          </Tooltip>
        )}
      </Label>
      <SelectDiv>
        <select {...rest}>
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {options?.map((option, y) => {
            return (
              <option value={option.name} key={y}>
                {option.displayName}
              </option>
            );
          })}
        </select>
      </SelectDiv>
    </StyledSelect>
  );
};

export default Select;
